import CourseHttpService from "../http/backend.http.service";

class CoursesEndpoint {
	#endpoint = "courses";

	findAll = async (token) => {
		return await CourseHttpService.get(token, this.#endpoint);
	};
	updateCourse = async (token, courseId, courseData) => await CourseHttpService.put(token, this.#endpoint + "/" + courseId, courseData);

	findStudentAllowedCourses = async (token) => {
		return await CourseHttpService.get(token, this.#endpoint + "/student-page");
	};

}

export default new CoursesEndpoint();