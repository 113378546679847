import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppCardHeader from "../../components/AppCardHeader";
import MDBox from "../../components/MDBox";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import Footer from "../../components/Footer";
import {useTranslation} from "react-i18next";
import CoursesEndpoint from "../../services/course/courses.endpoint";
import toast from "react-hot-toast";
import {useKeycloak} from "@react-keycloak/web";
import {config} from "../../Constants";
import {useNavigate} from "react-router-dom";
import {CardContent, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Icon } from "@iconify/react";


function CoursesList() {
    const {t} = useTranslation([], {useSuspense: false});
    const {keycloak} = useKeycloak();
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const [iconsState, setIconsState] = useState({});

    const filteredCourses = courses.filter((course) =>
        course.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        CoursesEndpoint.findStudentAllowedCourses(keycloak.token)
            .then((response) => {
                setCourses(response);
            })
            .catch((error) => {
                toast.error(t("Failed to fetch courses"));
            });
    }, []);



    const renderIcon = (icon) => {
        return <Icon icon={icon} style={{ fontSize: "48px" }} />;
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pb={3} pt={6}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <AppCardHeader title={t("Courses")} />
                            <Grid container spacing={2}>
                                {filteredCourses.length > 0 ? (
                                    <>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                            justifyContent="space-between"
                                            style={{ position: 'relative' }}
                                        >
                                            <Grid item xs={12}>
                                                <div style={{
                                                    position: 'absolute',
                                                    top: 50,
                                                    right: 10,
                                                }}>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder={t("Search...")}
                                                        size="small"
                                                        style={{marginRight: 16}}
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        {filteredCourses.map((course) => {
                                            if (!course) return null;

                                            const iconState = iconsState[course.id] || {};

                                            return (
                                                <Grid item xs={12} md={6} lg={3} sx={{ mt: 14 }} key={course.id}>
                                                    <MDBox mb={3}>
                                                        <Card
                                                            onClick={() => navigate(`${config.routes.courses.route}/${course.id}`)}
                                                            style={{ cursor: "pointer", width: "300px", margin: "0 auto" }}
                                                        >
                                                            <CardContent key={course.id} course={course} style={{ textAlign: "center" }}>
                                                                {iconState.selectedIcon ? (
                                                                    <Icon icon={iconState.selectedIcon} style={{ fontSize: "48px" }} />
                                                                ) : (
                                                                    renderIcon(course.icon)
                                                                )}
                                                                <Typography variant="h6" gutterBottom>
                                                                    {course?.name || "Course Name"}
                                                                </Typography>

                                                            </CardContent>
                                                        </Card>
                                                    </MDBox>
                                                </Grid>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
                                        <Typography variant="h6" color="textSecondary">
                                            {t("No courses available to display")}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </DashboardLayout>
    );
}

export default CoursesList;

